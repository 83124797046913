import React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import "../components/fonts.css";
import "../components/global.css";
import favicon from "../components/favicon.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

import Menu from "../components/Menu";
import Social from "../components/Social";
import Travel from "../components/travel";
import background from "../components/bioBg.mp4";

const Container = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: radial-gradient(900px at 75% 30%, #272e3d 0%, #272e3d 100%);
`;
const Contact = styled.div`
  height: 100%;
  overflow: hidden;
  video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    animation: videoFade 1s ease-in-out forwards,
      scaleUp 20s ease-in-out forwards;
  }
  h1,
  .email {
    position: relative;
    opacity: 0;
    animation: fade 1s ease-in-out forwards, slideUp 800ms ease-in-out forwards;
  }
  .email {
    animation-delay: 100ms;
  }
  h1 {
    font-size: 2.7em;
    font-family: merri-bold;
    padding: 10px 0 20px;
    @media (max-width: 500px) {
      font-size: 2em;
      padding: 10px 0 0;
      line-height: 100%;
    }
    @media (min-width: 1440px) {
      font-size: 4em;
    }
  }
  h2 {
    font-family: merri-light;
    color: #919cad;
    font-size: 1.2em;
    margin: 0;
    padding: 18px 0;
    max-width: 260px;
    margin: 0 auto;
    b {
      font-family: merri-reg;
      color: white;
    }
  }
  a {
    display: inline-block;
    width: 100%;
    padding: 30px 0 28px;
    color: #ccdaee;
    font-size: 1.4em;
    font-family: merri-bold;
  }
  .button {
    width: 80%;
    background: white;
    border: 0;
    border: 2px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    color: #415d86;
    font-family: gotham-bold;
    text-transform: uppercase;
    font-size: 0.9em;
    padding: 8px 10px;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    &.active {
      background: transparent;
      color: white;
    }
    &:hover {
      cursor: pointer;
      background: white;
      color: #272e3d;
      transform: scale(1.1);
    }
  }
  .wrapper {
    position: absolute;
    top: 50%;
    width: 100%;
    text-align: center;
    transform: translateY(-50%);
    color: white;
  }
  .faded {
    opacity: 0.3;
  }
  .line {
    display: inline-block;
    height: 3px;
    width: 90%;
    max-width: 355px;
    background: #919cad;
    opacity: 0.1;
    &.faded {
      opacity: 0.1;
    }
  }
  .social {
    width: 90%;
    max-width: 500px;
    margin: 0 auto;
    padding-bottom: 20px;
  }
  .clipboardText {
    font-family: gotham-bold;
    display: inline-block;
    width: 100%;
    left: 0;
    font-size: 1em;
    padding: 16px 0 0;
    opacity: 0;
    transition: all 130ms cubic-bezier(0.2, 0.8, 0.2, 1);
    &.active {
      opacity: 1;
      animation: clipAnimation 130ms ease-in-out forwards;
    }
  }
`;

const Email = styled.div`
  width: 100%;
  max-width: 240px;
  margin: 0 auto;
`;

function copySuccess() {
  var copySuccessBtn = document.getElementById("clipboard");
  copySuccessBtn.classList.toggle("active");
}

export default () => (
  <Container>
    <Helmet>
      <meta charSet="utf-8" />
      <title>David D. Ortiz | Product Designer</title>
      <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
      <link
        rel="alternate"
        href="https://www.davidortiz.me/contact/"
        hreflang="en-us"
      />
      <link rel="canonical" href="https://www.davidortiz.me/contact/" />
      <meta http-equiv="Content-Type" content="text/html; charset=utf-8" />
      <meta
        name="description"
        content="Product Designer | UX, Frontend &amp; Deployment for Remote Startups."
      />
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <meta property="og:title" content="David D. Ortiz | Product Designer" />
      <meta property="og:site_name" content="Personal Portfolio 2019" />
      <meta
        property="og:description"
        content="Product Designer | UX, Frontend &amp; Deployment for Remote Startups."
      />
    </Helmet>
    <Menu />
    <Contact>
      <video loop muted playsInline preload autoPlay="autoPlay">
        <source src={background} type="video/mp4" />
      </video>
      <div className="wrapper">
        <h1>Send me a direct message:</h1>

        <Email className="email">
          <a href="mailto:david@davidortiz.me">david@davidortiz.me</a>

          <br />
          <CopyToClipboard onCopy={copySuccess} text="david@davidortiz.me">
            <button className="button">Copy To Clipboard</button>
          </CopyToClipboard>

          <span id="clipboard" className="clipboardText">
            Successfully Copied
          </span>
        </Email>
        <div>
          <span className="line" />
        </div>
        <Social />
        <div>
          <Travel />
        </div>
      </div>
    </Contact>
  </Container>
);
