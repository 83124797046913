import React from "react"
import styled from "styled-components"

const Container = styled.div`
  h3 {
    font-size: 1.2em;
    font-family: merri-light;
    opacity: 0.9;
    padding: 10px;
  }
  .line {
    display: inline-block;
    vertical-align: middle;
    height: 3px;
    width: 90%;
    max-width: 455px;
    background: #919cad;
    opacity: 0.4;
  }
  .travelAnimated {
    display: flex;
    align-items: center;
    .line {
      width: 70px;
    }
  }
  &.left {
    .travel {
      text-align: left;
      margin: 0 auto;
    }
  }
  .travel {
    @media (max-width: 500px) {
      white-space: normal;
    }
    i {
      padding: 0 1rem;
    }
    a {
      @media (max-width: 500px) {
        padding: 20px 0;
      }
    }
    svg {
      margin: 0 6px;
    }
    div {
      flex: 0 100%;
      padding: 0 20px 0 0;
      width: 100%;
      text-align: left;
      @media (max-width: 500px) {
        text-align: center;
        padding: 0;
      }
    }
  }
  .faded {
    opacity: 0.4;
  }
  .line.faded {
    opacity: 0.2;
  }
  .travelHeader {
    @media (max-width: 500px) {
      display: none;
    }
  }
`

function Travel() {
  return (
    <Container>
      <h3 className='travelHeader'>Currently Working Remotely From:</h3>

      <div className='travel'>
        <span role='img' aria-label='San Diego'>
          <i>🇺🇸</i> Las Vegas, Nevada
        </span>
      </div>
    </Container>
  )
}

export default Travel
